import React from 'react'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '../context/global'
import styled from 'styled-components'
import Sidebar from './Sidebar'

function Airing({rendered}) {
    const {airingAnime ,isSearch, searchResults} = useGlobalContext()

    const conditionalRender = () => {
        if(!isSearch && rendered === 'airing'){
            return airingAnime?.map((anime) => {
                return <Link to={`/anime/${anime.mal_id}`} key={anime.mal_id}>
                    <img src={anime.images.jpg.large_image_url} alt="" />
                </Link>
            })
        }else{
            return searchResults?.map((anime) => {
                return <Link to={`/anime/${anime.mal_id}`} key={anime.mal_id}>
                    <img src={anime.images.jpg.large_image_url} alt="" />
                </Link>
            })
        }
    }

    return (
        <PopularStyled>
            <div className="airing-anime">
                {conditionalRender()}
            </div>
            <Sidebar />
        </PopularStyled>
    )
}

const PopularStyled = styled.div`
/* Default styles for .airing-anime */
.airing-anime {
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 5rem;
    padding-right: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 2rem;
    background-color: #fff;
    border-top: 5px solid #e5e7eb;
}

.airing-anime a {
    height: 500px;
    border-radius: 7px;
    border: 5px solid #e5e7eb;
}

.airing-anime a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
    .airing-anime {
        padding-left: 2rem;
    }
    .airing-anime a {
        height: auto;
    }
    .airing-anime a img {
        height: auto;
    }
}

/* Media Query for even smaller screens */
@media (max-width: 576px) {
    .airing-anime {
        padding-left: 1rem;
    }
}

/* Media Query for larger screens (e.g., tablets) */
@media (min-width: 992px) {
    .airing-anime {
        padding-left: 7rem;
    }
}

/* Media Query for extra-large screens (e.g., desktops) */
@media (min-width: 1200px) {
    .airing-anime {
        padding-left: 10rem;
    }
}

/* Add more media queries as needed for different screen sizes */

`;

export default Airing