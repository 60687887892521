import React from 'react'
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components'
import { useGlobalContext } from '../context/global';

function Gallery() {
    const {getAnimePictures, pictures} = useGlobalContext()
    const {id} = useParams();

    //state
    const [index, setIndex] = React.useState(0);

    const handleImageClick = (i) => {
        setIndex(i)
    }


    React.useEffect(() => {
        getAnimePictures(id)
    }, [id])

    return (
        <GalleryStyled>
            <div className="back">
                <Link to="/">
                    <i className="fas fa-arrow-left"></i>
                    Back to Home
                </Link>
            </div>
            <div className="big-image">
                <img src={pictures[index]?.jpg.image_url} alt="" />
            </div>
            <div className="small-images">
                {pictures?.map((picture, i) => {
                    return <div className="image-con" onClick={()=>{
                        handleImageClick(i)
                    }} key={i}>
                        <img 
                            src={picture?.jpg.image_url}
                            style={{
                                border: i === index ? "3px solid #27AE60" : "3px solid #e5e7eb",
                                filter: i === index ? 'grayscale(0)' : 'grayscale(60%)',
                                transform: i === index ? 'scale(1.1)' : 'scale(1)',
                                transition: 'all .3s ease-in-out'
                            }}
                            alt="" 
                        />
                    </div>
                })}
            </div>
        </GalleryStyled>
    )
}

const GalleryStyled = styled.div`
/* Default styles */
background-color: #EDEDED;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;

.back {
    position: absolute;
    top: 2rem;
    left: 2rem;
    a {
        font-weight: 600;
        text-decoration: none;
        color: #EB5757;
        display: flex;
        align-items: center;
        gap: .5rem;
    }
}

.big-image {
    display: inline-block;
    padding: 2rem;
    margin: 2rem 0;
    background-color: #fff;
    border-radius: 7px;
    border: 5px solid #e5e7eb;
    position: relative;
    img {
        width: 350px;
        max-width: 100%; /* Ensure the image doesn't exceed its container width */
    }
}

.small-images {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    width: 80%;
    padding: 2rem;
    border-radius: 7px;
    background-color: #fff;
    border: 5px solid #e5e7eb;
}

.small-images img {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
    cursor: pointer;
    border-radius: 5px;
    border: 3px solid #e5e7eb;
}

/* Media Query for smaller screens (e.g., tablets) */
@media screen and (max-width: 768px) {
    .big-image {
        padding: 1rem; /* Adjust padding for medium screens */
    }
    .small-images {
        width: 100%; /* Expand to full width for medium screens */
    }
    .small-images img {
        width: 8rem; /* Increase image size for medium screens */
        height: 8rem;
    }
}

/* Media Query for smaller screens (e.g., smartphones) */
@media screen and (max-width: 576px) {
    .back {
        position: static; /* Remove absolute positioning for small screens */
        margin-top: 2rem; /* Add margin for spacing on top */
    }
    .big-image {
        padding: 1rem; /* Adjust padding for small screens */
        margin: 1rem 0; /* Adjust margin for small screens */
        img {
            width: 100%; /* Make the image responsive within its container */
        }
    }
    .small-images {
        width: 100%; /* Expand to full width for small screens */
        padding: 1rem; /* Adjust padding for small screens */
    }
    .small-images img {
        width: 100%; /* Make the images responsive within their container */
        height: auto;
    }
}

/* Add more media queries as needed for different screen sizes */

`;

export default Gallery