import React from 'react'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '../context/global'
import styled from 'styled-components'
import Sidebar from './Sidebar'

function Upcoming({rendered}) {
    const {upcomingAnime ,isSearch, searchResults} = useGlobalContext()

    const conditionalRender = () => {
        if(!isSearch && rendered === 'upcoming'){
            return upcomingAnime?.map((anime) => {
                return <Link to={`/anime/${anime.mal_id}`} key={anime.mal_id}>
                    <img src={anime.images.jpg.large_image_url} alt="" />
                </Link>
            })
        }else{
            return searchResults?.map((anime) => {
                return <Link to={`/anime/${anime.mal_id}`} key={anime.mal_id}>
                    <img src={anime.images.jpg.large_image_url} alt="" />
                </Link>
            })
        }
    }

    return (
        <PopularStyled>
            <div className="upcoming-anime">
                {conditionalRender()}
            </div>
            <Sidebar />
        </PopularStyled>
    )
}

const PopularStyled = styled.div`
/* Default styles */
display: flex;

.upcoming-anime {
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 5rem;
    padding-right: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 2rem;
    background-color: #fff;
    border-top: 5px solid #e5e7eb;
}

.upcoming-anime a {
    height: 500px;
    border-radius: 7px;
    border: 5px solid #e5e7eb;
}

.upcoming-anime a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

/* Media Query for smaller screens (e.g., tablets) */
@media screen and (max-width: 768px) {
    .upcoming-anime {
        padding-left: 2rem; /* Adjust left padding for medium screens */
    }
    .upcoming-anime a {
        height: 350px; /* Reduce height for medium screens */
    }
}

/* Media Query for smaller screens (e.g., smartphones) */
@media screen and (max-width: 576px) {
    .upcoming-anime {
        padding: 1rem 2rem; /* Adjust padding for small screens */
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); /* Full width columns for small screens */
        grid-gap: 1rem; /* Reduce gap for small screens */
    }
    .upcoming-anime a {
        height: auto; /* Adjust height for small screens */
    }
    .upcoming-anime a img {
        border-radius: 0; /* Remove border radius for small screens */
    }
}

/* Add more media queries as needed for different screen sizes */

`;

export default Upcoming